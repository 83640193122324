import React from "react";
import './EventCard.scss';
import {currentLocale, t} from '../../i18n/index';
import {Link} from 'gatsby';

const EventCard = ({data, imageURL}) => {
    return (
        <div className="content all-events__content card">
            <div className="all-events__item item item-2">
                <img className="all-events__event-image" src={imageURL} alt={data.name}/>
            </div>
            <div className="all-events__item item item-2 all-events__description">
                <h1 className="title">{data.name}</h1>
                <div>
                    {data.resume.content.map((paragraph, index) => <p key={index}>{paragraph.content[0].value}</p>)}
                </div>
                <Link to={`/${currentLocale()}/${data.name}`} className="link link-orange">{t('pastEvents.toEvent')}</Link>
            </div>
        </div>);
};

export default EventCard;