import React from 'react';
import {graphql} from 'gatsby'

import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import EventCard from "../components/event-card/EventCard";
import '../styles/style.scss';
import {t} from "../i18n";

const PastEvents = (props) => {
    return (
        <div>
            <Navbar currentPage="past-events" />
            <div className="has-static-nav">
                <div className="bg bg-small bg-light-grey">
                    <div className="container">
                        <h1 className="title">{t('pastEvents.title')}</h1>
                        <p>{t('pastEvents.subtitle')} <a href="http://youtube.dohack.io" className="link link-orange">YouTube Channel</a>!</p>
                        {props.eventData.allContentfulEvent.edges
                            .filter(event => props.eventData.site.siteMetadata.currentEvent !== event.node.name)
                            .map(eventNode =>
                            (<EventCard key={eventNode.node.name} data={eventNode.node} imageURL={eventNode.node.groupImage.localFile.childImageSharp.fluid.src}/>)
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ({data}) => {
    return (<PastEvents eventData={data}/>);
};

export const data = graphql`
    query allEvents($locale: String) {
        allContentfulEvent(filter: {node_locale: {eq: $locale}}, sort: {fields: name, order: DESC}) {
            edges {
                node {
                    name
                    resume {
                        content {
                            content {
                                value
                            }
                        }
                    }
                    groupImage {
                        localFile {
                            childImageSharp {
                                fluid {
                                    src
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                currentEvent
            }
        }
    }
`;
